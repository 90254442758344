<template>
  <div class="tools" v-if="hasPerm('home')">
    <page-header title="Outils" icon="fas fa-tools"></page-header>
    <b-row>
      <b-col>
        <div>
          <ul class="app-menu">
            <li v-if="hasPerm('home.view_place')">
              <router-link :to="{ name: 'tools-places', }">
                <i class="fas fa-list"></i> Liste des lieux
              </router-link>
            </li>
            <li v-if="hasPerm('agenda.view_activitydate')">
              <router-link :to="{ name: 'agenda-activity-dates', }">
                <i class="fa fa-location-dot"></i> Occupation des lieux
              </router-link>
            </li>
            <li v-if="hasPerm('agenda.view_activitydate')">
              <router-link :to="{ name: 'agenda-day-dates', }">
                <i class="fa fa-calendar-day"></i> Agenda du jour
              </router-link>
            </li>
            <li v-if="hasPerm('schools.view_school')">
              <router-link :to="{ name: 'schools-list', }">
                <i class="fa fa-school"></i> Écoles
              </router-link>
            </li>
            <li v-if="hasPerm('schools.view_schoollevel')">
              <router-link :to="{ name: 'school-levels-list', }">
                <i class="fa fa-school"></i> Niveaux scolaires
              </router-link>
            </li>
            <li v-if="hasPerm('people.view_city')">
              <router-link :to="{ name: 'streets-list', }">
                <i class="fas fa-house-chimney"></i> Liste des villes, quartiers et rues
              </router-link>
            </li>
            <li v-if="hasPerm('families.change_family')">
              <router-link :to="{ name: 'archive-families', }">
                <i class="fa fa-users-slash"></i> Familles à archiver
              </router-link>
            </li>
            <li v-if="hasPerm('people.view_individual')">
              <router-link :to="{ name: 'birthdays-between', }">
                <i class="fas fa-birthday-cake"></i> Anniversaires
              </router-link>
            </li>
            <li v-if="hasPerm('families.view_family')">
              <router-link :to="{ name: 'single-individuals', }">
                <i class="fa fa-th-list"></i> Liste des individus hors famille et structures
              </router-link>
            </li>
            <li
              v-if="hasOnePerm(['activity.change_activityinscription', 'youth.change_youthhomeactivityinscription'])"
            >
              <router-link :to="{ name: 'renew-activity-inscriptions', }">
                <i class="fa fa-person-walking-dashed-line-arrow-right"></i>
                Renouvellement/Transfert d'inscriptions aux activités
              </router-link>
            </li>
            <pages-list apply-to="tools"></pages-list>
          </ul>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/Layout/PageHeader'
import PagesList from '@/components/Pages/PagesList.vue'
import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'tools-home',
  mixins: [BackendMixin],
  components: {
    PagesList,
    PageHeader,
  },
}
</script>

<style lang="less">
</style>
